@charset 'UTF-8';
/*!
 * halexistar
 *
 * @year 2018
 * @copyright Ilion
 *
 */
html,
body
{
    overflow-x: hidden;

    max-width: 100%;
}

:active,
:focus
{
    outline: 0 !important;
}

a
{
    color: inherit;
}

a:hover
{
    text-decoration: none;
}

address,
p,
dl,
blockquote,
pre,
td,
th,
label,
textarea
{
    text-rendering: optimizespeed;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
svg
{
    max-width: 100%;
    height: auto;
}

object,
embed
{
    position: relative;

    max-width: 100%;
    height: 100%;
}

embed,
img,
object,
svg
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;

    -webkit-user-drag: none;
}

a,
.btn,
input,
select,
textarea
{
    -webkit-transition: all .24s cubic-bezier(0, 0, .58, 1);
         -o-transition: all .24s cubic-bezier(0, 0, .58, 1);
            transition: all .24s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

/* 
* BOOTSTRAP 4 reset
*/
.navbar
{
    padding-right: 0;
    padding-left: 0;
}

.badge
{
    border-radius: 0;
}

@media (max-width: 991px)
{
    .lead
    {
        font-size: 1rem;
    }
    .display-1
    {
        font-size: 3rem;
    }
    .display-2
    {
        font-size: 2.5rem;
    }
    .display-3
    {
        font-size: 2rem;
    }
    .display-4
    {
        font-size: 1.5rem;
    }
}

.btn-primary
{
    color: white;
}

.list-menu li
{
    line-height: 2rem;
}

.fs-normal
{
    font-size: 1rem;
}

.fs-small
{
    font-size: small;
}

.fs-medium
{
    font-size: 1.25rem;
}

.fs-title
{
    font-size: 1.75rem;
}

.form-group:last-child,
p:last-child,
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child
{
    margin-bottom: 0;
}

.pagination
{
    font-family: 'Lora';
    font-weight: normal;
    font-style: italic;
}

.pagination .page-item + .page-item
{
    margin-left: .5rem;
}

.pagination .page-link
{
    border: 0 none !important;
    border-radius: 0 !important;
}

.pagination .page-item:not(.active) .page-link
{
    color: #606060;
    -webkit-box-shadow: inset 0 0 0 2px #f3f3f3;
            box-shadow: inset 0 0 0 2px #f3f3f3;
}

.modal-open .modal
{
    background-color: rgba(0, 0, 0, .33);
}

#html .modal-backdrop
{
    display: none !important;
}

#html .btn-success
{
    color: white;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: italic;

    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v15/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: normal;

    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;

    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: normal;

    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: normal;

    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: italic;

    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v15/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: normal;

    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;

    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: normal;

    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Playfair Display';
    font-weight: 400;
    font-style: italic;

    src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFkD-vYSZviVYUb_rj3ij__anPXDTnogkk7yRZrPA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Playfair Display';
    font-weight: 700;
    font-style: italic;

    src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFnD-vYSZviVYUb_rj3ij__anPXDTngOWwu5DRmFqWF_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Playfair Display';
    font-weight: 900;
    font-style: italic;

    src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFnD-vYSZviVYUb_rj3ij__anPXDTngAW4u5DRmFqWF_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Playfair Display';
    font-weight: 400;
    font-style: normal;

    src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgEM86xQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Playfair Display';
    font-weight: 700;
    font-style: normal;

    src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lW4e5j5hNKc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Playfair Display';
    font-weight: 900;
    font-style: normal;

    src: local('Playfair Display Black'), local('PlayfairDisplay-Black'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFlD-vYSZviVYUb_rj3ij__anPXBb__lW4e5j5hNKc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: normal;

    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;

    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;

    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: normal;

    src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Poppins';
    font-weight: 100;
    font-style: normal;

    src: local('Poppins Thin'), local('Poppins-Thin'), url(https://fonts.gstatic.com/s/poppins/v5/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Poppins';
    font-weight: 200;
    font-style: normal;

    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Poppins';
    font-weight: 300;
    font-style: normal;

    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;

    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;

    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;

    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.-opensans
{
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: normal;
}

.-playfair
{
    font-family: 'Playfair Display';
    font-weight: normal;
    font-style: italic;
}

.-montserrat
{
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;

    letter-spacing: -1px;
    word-spacing: .25rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
}

.-poppins
{
    font-family: 'Poppins';
    font-weight: normal;
    font-style: normal;
}

/**
 * Micro clearfix
 * Copyright © Nicolas Gallagher
 * Source: [http://nicolasgallagher.com/micro-clearfix-hack/]
 */
.cf::before,
.cf::after,
.clearfix::before,
.clearfix::after
{
    display: table;

    content: ' ';
}

.cf::after,
.clearfix::after
{
    clear: both;
}

.main-content,
.main-header,
.main-footer
{
    clear: both;
}

.embed-responsive.-anime img
{
    transition: all .4s cubic-bezier(0, 0, .58, 1);
    transform: scale(1);
    transform: translate3d(0, 0, 0);
}

.embed-responsive.-anime:hover img
{
    transform: scale(1.2);
}

.bg-image
{
    overflow: hidden;

    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
}

.bg-facebook
{
    background-color: #4267b2;
}

.bg-instagram
{
    background-image: radial-gradient(circle farthest-corner at 32% 106%, #ffe17d 0, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), linear-gradient(135deg, #234bd7 12%, #c33cbe 58%);
}

.bg-twitter
{
    background-color: #03b8ec;
}

.bg-linkedin
{
    background-color: #0284ba;
}

.bg-googleplus
{
    background-color: #ee5352;
}

.bg-youtube,
.bg-youTube
{
    background-color: #ff0416;
}

.bg-pinterest
{
    background-color: #bd081c;
}

.line-clamp,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4
{
    display: -webkit-box;
    overflow: hidden;

        text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

    -webkit-box-orient: vertical;
     -o-text-overflow: ellipsis;
    -webkit-line-clamp: 1;
}

.line-clamp-2
{
    -webkit-line-clamp: 2;
}

.line-clamp-3
{
    -webkit-line-clamp: 3;
}

.line-clamp-4
{
    -webkit-line-clamp: 4;
}

/* Techniques that make content inaccessible (invisible to all users).
* [https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html]*/
.invisible,
.visually-hidden
{
    position: absolute !important;

    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);

    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;

    border: 0 !important;
}

:root:hover .invisible *,
:root:hover .visually-hidden *
{
    display: none !important;
}

.truncate,
.ellipsis
{
    overflow: hidden;

    white-space: nowrap;
        text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

     -o-text-overflow: ellipsis;
}

.vertical-align
{
    position: relative;
    top: 50%;

    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

/* select */
.styled-select
{
    position: relative;

    display: inline-block;

    width: 100%;

    white-space: nowrap;
}

.styled-select select option[selected]
{
    cursor: default;

    color: black;
    background-color: #f3f3a0;
    text-shadow: 0 0 0;
}

.styled-select select
{
    display: block;
    overflow: hidden;

    width: 100%;
    padding-right: 37px;

    white-space: nowrap;
        text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

     -o-text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
}

.styled-select::after
{
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;

    width: 10px;
    height: 10px;
    margin: -2px 1em 0 0;

    content: '';
    -webkit-transition: all 180ms ease-out;
         -o-transition: all 180ms ease-out;
            transition: all 180ms ease-out;
    -webkit-transform: translateY(-50%) scale(.8) rotate(45deg);
        -ms-transform: translateY(-50%) scale(.8) rotate(45deg);
            transform: translateY(-50%) scale(.8) rotate(45deg);

    border: solid #b0b0b0;
    border-width: 0 3px 3px 0;
}

.styled-select:hover::after,
.styled-select:focus::after
{
    -webkit-transform: translateY(-50%) scale(.9) rotate(45deg);
        -ms-transform: translateY(-50%) scale(.9) rotate(45deg);
            transform: translateY(-50%) scale(.9) rotate(45deg);

    border-color: #999;
}

@-webkit-keyframes fadeIn
{
    0%
    {
        opacity: 0;
    }
}

@keyframes fadeIn
{
    0%
    {
        opacity: 0;

        -webkit-filter: alpha opacity=0;
           -moz-filter: alpha opacity=0;
                filter: alpha opacity=0;
    }
}

.fade-in
{
    -webkit-animation: fadeIn 1s normal none;
            animation: fadeIn 1s normal none;
    -webkit-animation-timing-function: cubic-bezier(.77, 0, .175, 1);
            animation-timing-function: cubic-bezier(.77, 0, .175, 1);
}

.fade-in.-xs
{
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

.fade-in.-s
{
    -webkit-animation-duration: 1500ms;
            animation-duration: 1500ms;
}

.fade-in.-m
{
    -webkit-animation-duration: 2000ms;
            animation-duration: 2000ms;
}

.fade-in.-l
{
    -webkit-animation-duration: 2500ms;
            animation-duration: 2500ms;
}

.fade-in.-xl
{
    -webkit-animation-duration: 3000ms;
            animation-duration: 3000ms;
}

.fade-in.-xxl
{
    -webkit-animation-duration: 3500ms;
            animation-duration: 3500ms;
}

.cover
{
    position: relative;
    z-index: 1;

    display: block;
    overflow: hidden;

    width: 100%;

    text-align: center;
}

.media .cover
{
    max-width: 4rem;

    -webkit-box-flex: 1;
    -ms-flex: 1 0 4rem;
        flex: 1 0 4rem;
}

.list-media .media .cover
{
    max-width: 25%;

    -webkit-box-flex: 1;
    -ms-flex: 1 0 25%;
        flex: 1 0 25%;
}

.cover::before
{
    display: block;

    padding-top: 100%;

    content: '';
}

.cover img
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: auto;
    max-width: none;
    height: 100%;

    border: 0;
}

.cover::after
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    content: '';

    opacity: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(#434c63), to(#bc9b72));
    background-image: -webkit-linear-gradient(right, #434c63, #bc9b72);
    background-image:      -o-linear-gradient(right, #434c63, #bc9b72);
    background-image:         linear-gradient(to left, #434c63, #bc9b72);
}

.cover img,
.cover::after
{
    -webkit-transition: all .24s cubic-bezier(0, 0, .58, 1);
         -o-transition: all .24s cubic-bezier(0, 0, .58, 1);
            transition: all .24s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

a.cover::after
{
    font-family: 'fonticon';
    font-size: 2rem;
    line-height: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    content: '\e92d';
    -webkit-transform: scale(.95);
        -ms-transform: scale(.95);
            transform: scale(.95);

    color: white;

    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.cover:hover::after
{
    font-size: 2.25rem;

    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);

    opacity: .85;
}

.cover:hover img
{
    -webkit-transition: all 2s cubic-bezier(0, 0, .58, 1);
         -o-transition: all 2s cubic-bezier(0, 0, .58, 1);
            transition: all 2s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.embed-content
{
    position: absolute;
    z-index: auto;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.embed-content.-caption
{
    z-index: 20;
}

.embed-content.-top
{
    top: 0;
    bottom: auto;

    height: auto;
    padding: .5rem;
}

.embed-content.-bottom
{
    top: auto;
    bottom: 0;

    height: auto;
    padding: .5rem;
}

.embed-content.-middle
{
    top: 50%;
    bottom: auto;

    height: auto;
    padding: .5rem;

    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

/* slick slider */
.carousel,
.slick-slider
{
    position: relative;
    z-index: auto;

    clear: both;
}

#html .slide-control
{
    font-size: 2.25rem;
    line-height: 3rem;

    position: absolute;
    z-index: 2;
    top: 50%;

    width: 3rem;
    height: 3rem;
    padding: 0;

    -webkit-transition: all .24s cubic-bezier(0, 0, .58, 1);
         -o-transition: all .24s cubic-bezier(0, 0, .58, 1);
            transition: all .24s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);

    opacity: 1;
    color: #333;
    border: 0 none;
    border-radius: 0;
}

#html .slide-control .fa
{
    position: relative;

    -webkit-transition: all .14s cubic-bezier(0, 0, .58, 1);
         -o-transition: all .14s cubic-bezier(0, 0, .58, 1);
            transition: all .14s cubic-bezier(0, 0, .58, 1);
}

#html .slide-control .fa::after
{
    position: absolute;
    z-index: 3;
    top: 50%;

    display: inline-block;

    width: 3rem;
    height: 3px;

    content: '';

    background-color: #333;
}

#html .slide-control.-prev
{
    left: 0;

    margin-left: -1.5rem;
}

#html .slide-control.-prev .fa
{
    -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
            transform: translateX(50%);
}

#html .slide-control.-prev .fa::after
{
    left: 2px;
}

#html .slide-control.-next
{
    right: 0;

    margin-right: -1.5rem;
}

#html .slide-control.-next .fa
{
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}

#html .slide-control.-next .fa::after
{
    right: 2px;
}

#html .slide-control:hover .fa
{
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.slick-dots
{
    position: relative;

    padding-top: 2rem;
}

.slick-dots li
{
    width: auto;
    height: auto;
    margin: 0;
}

.slick-dots li + li
{
    margin-left: 1rem;
}

.slick-dots li button
{
    width: 12px;
    height: 12px;
    margin: 0;

    border-width: 1px;
    border-radius: 0;
    background-color: #e7e7e7;
}

.slick-dots li button::before
{
    display: none;

    content: '';
}

.slick-dots button:hover,
.slick-dots .slick-active button
{
    background-color: #c6a381;
}

#html .slick-dots button:hover::after,
#html .slick-dots .slick-active button::after
{
    position: relative;
    z-index: 1;
    top: -15px;
    bottom: 100%;

    display: block;

    width: 10px;
    height: 10px;
    margin-left: -4px;

    content: '';

    opacity: 1;
    border: 5px solid transparent;
    border-bottom: 5px solid #c6a381;
    background: none !important;
}

.slick-hidden.-arrows .slick-dots,
.slick-hidden.-controls .slide-control
{
    display: none !important;
    visibility: hidden !important;
}

.slick-dotted.slick-slider.slick-hidden.-controls
{
    margin-bottom: 0;
}

.form.-grey .form-control
{
    border: 0 none;
    border-radius: 0;
    background-color: #f3f3f3;
}

#html .form-control.form-control-lg
{
    font-size: 1rem;

    padding-right: 1rem;
    padding-left: 1rem;
}

.form.-white .form-control
{
    border: 0 none;
    border-radius: 0;
    background-color: #fff;
    -webkit-box-shadow: inset 0 -2px 0 0 #e7e7e7;
            box-shadow: inset 0 -2px 0 0 #e7e7e7;
}

.form.-white .form-control:hover
{
    -webkit-box-shadow: inset 0 -2px 0 0 #c6a381;
            box-shadow: inset 0 -2px 0 0 #c6a381;
}

.form.-white .form-control:focus
{
    background-color: #f7f7f7;
    -webkit-box-shadow: inset 0 -2px 0 0 #c6a381;
            box-shadow: inset 0 -2px 0 0 #c6a381;
}

.form.-hidden-labels label
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;
}

form div.error
{
    font-size: .8rem;
    line-height: 2;

    display: block;
    clear: both;

    -webkit-animation: fadeIn 1s normal none;
            animation: fadeIn 1s normal none;
    -webkit-animation-timing-function: cubic-bezier(.77, 0, .175, 1);
            animation-timing-function: cubic-bezier(.77, 0, .175, 1);
    white-space: normal;

    color: #e23535;
}

form .text-danger,
form .text-success
{
    font-size: .85rem;
    line-height: 2;
}

input.error,
select.error,
textarea.error
{
    color: #dc7878;
    background-color: #ffe4e4;
    -webkit-box-shadow: inset 0 0 0 1px #dc7878;
            box-shadow: inset 0 0 0 1px #dc7878;
}

input.success,
select.success,
textarea.success
{
    color: #49b381;
    background-color: #ebfff9;
    -webkit-box-shadow: inset 0 0 0 1px #49b381;
            box-shadow: inset 0 0 0 1px #49b381;
}

/* 
* BOOTSTRAP 4 reset
*/
.navbar
{
    padding-right: 0;
    padding-left: 0;
}

.badge
{
    border-radius: 0;
}

@media (max-width: 991px)
{
    .lead
    {
        font-size: 1rem;
    }
    .display-1
    {
        font-size: 3rem;
    }
    .display-2
    {
        font-size: 2.5rem;
    }
    .display-3
    {
        font-size: 2rem;
    }
    .display-4
    {
        font-size: 1.5rem;
    }
}

.btn-primary
{
    color: white;
}

.list-menu li
{
    line-height: 2rem;
}

.fs-normal
{
    font-size: 1rem;
}

.fs-small
{
    font-size: small;
}

.fs-medium
{
    font-size: 1.25rem;
}

.fs-title
{
    font-size: 1.75rem;
}

.form-group:last-child,
p:last-child,
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child
{
    margin-bottom: 0;
}

.pagination
{
    font-family: 'Lora';
    font-weight: normal;
    font-style: italic;
}

.pagination .page-item + .page-item
{
    margin-left: .5rem;
}

.pagination .page-link
{
    border: 0 none !important;
    border-radius: 0 !important;
}

.pagination .page-item:not(.active) .page-link
{
    color: #606060;
    -webkit-box-shadow: inset 0 0 0 2px #f3f3f3;
            box-shadow: inset 0 0 0 2px #f3f3f3;
}

.modal-open .modal
{
    background-color: rgba(0, 0, 0, .33);
}

#html .modal-backdrop
{
    display: none !important;
}

#html .btn-success
{
    color: white;
}

/* Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap */
/* Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */
/* Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, less than 768px) */
/* Medium devices (tablets, less than 992px) */
/* Large devices (desktops, less than 1200px) */
/* Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */
#html .c-main
{
    color: #0a1e50;
}

#html .c-contrast
{
    color: #1e1e54;
}

#html .c-alt
{
    color: #20638e;
}

#html .c-alt2
{
    color: #32373c;
}

#html .c-blue
{
    color: #015c8b;
}

#html .bg-white
{
    background-color: white;
}

#html a:hover
{
    text-shadow: 0 0 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
}

#html .link-flag
{
    line-height: 80px;

    display: inline-block;

    margin-right: 2rem;

    vertical-align: top;
}

#html .link-flag .flag
{
    width: 24px;

    border-radius: 2px;
}

#html .header .link-login
{
    font-weight: 600;

    padding: 1rem 0;

    text-transform: uppercase;
}

#html .bg--img
{
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 50%;

    overflow: hidden;

    height: 100%;

    pointer-events: none;
    /* background-attachment: fixed; */

    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;

        -ms-background-size: cover;
}

#html .bg--img.left
{
    left: 0;
}

#html .form-check-label
{
    margin: 0;
}

#html .form-check-label .form-check-input
{
    margin-right: 10px;
}

#html .bg-overlay-dark::before
{
    background-color: rgba(1, 60, 106, .65);
}

#html .portfolio-item .portfolio--action
{
    background-color: rgba(32, 99, 142, .85);
}

#html .portfolio-item .pos-vertical-center
{
    padding: 1rem 2rem;
}

#html .portfolio-item p
{
    font-size: 14px;

    color: white;
}

#html .footer .logo-footer
{
    max-width: 200px;
    margin-bottom: 20px;

    opacity: .3;
}

#html .footer .footer-social-icon a
{
    width: 2rem;
    height: 2rem;
}

#html .footer .footer-social-icon .fa
{
    font-size: 2rem;
    line-height: 1;
}

@keyframes animeDropdown
{
    from
    {
        -webkit-transform: translateY(-3rem);
                transform: translateY(-3rem);

        opacity: 0;
    }
}

#html .dropdown-menu
{
    -webkit-animation: animeDropdown .5s 0s normal ease both;
            animation: animeDropdown .5s 0s normal ease both;
    -webkit-animation-timing-function: cubic-bezier(.16, .56, .17, .999);
            animation-timing-function: cubic-bezier(.16, .56, .17, .999);
}

@media only screen and (min-width: 992px)
{
    #html .has-dropdown.-widemenu .dropdown-menu
    {
        display: none;

        height: 80vh;

        background: transparent !important;
    }
    #html .has-dropdown.-widemenu .dropdown-menu::after
    {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        left: 0;

        display: block;

        width: 150vw;
        height: 100%;

        content: ' ';
        transform: translateX(-50%);

        background-color: rgba(1, 92, 139, .96);
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
    }
    #html .has-dropdown.-widemenu .dropdown-menu li a
    {
        margin: 0;

        color: #fff;
        border-bottom: 1px solid #4780a7;
    }
    #html .has-dropdown.-widemenu.mega-dropdown
    {
        position: relative !important;
    }
    #html .has-dropdown.-widemenu.mega-dropdown > ul > li
    {
        display: flex;

        width: 1170px;

        transform: translateX(-39%);

        flex-flow: row wrap;
        justify-content: center;
    }
    #html .has-dropdown.-widemenu.mega-dropdown > ul > li > ul
    {
        min-width: 200px;
    }
    #html .has-dropdown.-widemenu.mega-dropdown > ul > li > ul + ul
    {
        margin-left: 30px;
    }
    #html .has-dropdown:hover > .dropdown-menu,
    #html .dropdown-submenu:hover > .dropdown-menu
    {
        display: block;
    }
}

#html .tabs-timeline .crumbs ul
{
    display: flex;
    overflow: hidden;

    margin: 0;
    padding: 0;

    list-style: none;

    border: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #20638e;
    box-shadow: 0 2px 3px rgba(40, 40, 40, .16);

    flex-flow: row wrap;
}

#html .tabs-timeline .crumbs li
{
    flex-grow: 1;
    flex-basis: 0;
}

#html .tabs-timeline .crumbs li a
{
    line-height: 50px;

    position: relative;

    display: block;

    height: 50px;
    padding-right: 33.333%;

    text-align: center;
    text-decoration: none;

    color: #fff;
    background: #20638e;
}

#html .tabs-timeline .crumbs li:nth-child(even) a
{
    background-color: #175379;
}

#html .tabs-timeline .crumbs li:nth-child(even) a:before
{
    border-color: #175379;
    border-left-color: transparent;
}

#html .tabs-timeline .crumbs li:nth-child(even) a:after
{
    border-left-color: #175379;
}

#html .tabs-timeline .crumbs li:first-child a
{
    padding-left: 15px;

    border-radius: 4px 0 0 0;
}

#html .tabs-timeline .crumbs li:first-child a:before
{
    border: none;
}

#html .tabs-timeline .crumbs li:last-child a
{
    padding-right: 15px;

    border-radius: 0 4px 0 0;
}

#html .tabs-timeline .crumbs li:last-child a:after
{
    border: none;
}

#html .tabs-timeline .crumbs li a:before,
#html .tabs-timeline .crumbs li a:after
{
    position: absolute;
    top: 0;

    width: 0;
    height: 0;

    content: '';
    transition: all .24s cubic-bezier(0, 0, .58, 1);
    transform: translate3d(0, 0, 0);

    border: 0 solid #20638e;
    border-width: 25px 10px;
}

#html .tabs-timeline .crumbs li a:before
{
    left: -20px;

    border-left-color: transparent;
}

#html .tabs-timeline .crumbs li a:after
{
    left: 100%;

    border-color: transparent;
    border-left-color: #20638e;
}

#html .tabs-timeline .crumbs li a:hover
{
    font-weight: 600;

    color: #017097;
    background-color: #fff;
}

#html .tabs-timeline .crumbs li a:hover:before
{
    border-color: #fff;
    border-left-color: transparent;
}

#html .tabs-timeline .crumbs li a:hover:after
{
    border-left-color: #fff;
}

#html .tabs-timeline .crumbs li a:active
{
    font-weight: 600;

    color: #017097;
    background-color: #fff;
}

#html .tabs-timeline .crumbs li a:active:before
{
    border-color: #fff;
    border-left-color: transparent;
}

#html .tabs-timeline .crumbs li a:active:after
{
    border-left-color: #fff;
}

#html .tabs-timeline .crumbs li.active a
{
    font-weight: 600;

    color: #017097;
    background-color: #fff;
}

#html .tabs-timeline .crumbs li.active a:before
{
    border-color: #fff;
    border-left-color: transparent;
}

#html .tabs-timeline .crumbs li.active a:after
{
    border-left-color: #fff;
}

#html .tabs-timeline .tab-content > .tab-pane
{
    padding: 0;
}

#html .tabs-timeline .tab-content > .tab-pane .blog-entry
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#html .tabs-timeline .tab-content > .tab-pane .entry--bio + .entry--bio
{
    margin-top: 30px;
}

#html .tabs-timeline .tab-content > .tab-pane .entry--bio img
{
    float: left;

    max-width: 156px;
    margin-right: 30px;
}

#html .rev_slider .slide--headline-border
{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 106%;
    height: 550%;

    transform: translateY(-15%) translateX(-3%);
    pointer-events: none;

    box-shadow: 0 0 0 20px rgba(255, 255, 255, .25);
}

#html .table.-produto
{
    width: 100%;
    margin: 0;
    padding: 0;

    table-layout: fixed;
}

#html .table.-produto thead
{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    line-height: 1;

    text-transform: uppercase;
}

#html .table.-produto td:first-child
{
    width: 40%;
}

#html .pagination.-produto
{
    width: 100%;
    padding-top: 3rem;

    text-align: center;
}

#html .pagination.-produto > li:hover a
{
    color: white;
}

#html .tab-content.-bulario .tab-pane
{
    padding-top: 6rem;
}

#html .table.-bulario th,
#html .table.-bulario td
{
    line-height: 1 !important;

    padding: 1rem 2rem;

    vertical-align: middle;
}

#html .table.-bulario .item .fa
{
    font-size: 1.5rem;

    margin-right: 1rem;
}

#html .table.-bulario .th-input-search
{
    width: 25%;
}

#html .table.-bulario .input
{
    font-weight: normal;
    line-height: 3rem;

    width: 100%;
    height: auto;
    padding: 0 2rem;

    border: 0;
    border-radius: 3rem;
    box-shadow: 0 0 0 1px #aaa;
}

#html .table.-bulario .input:hover
{
    box-shadow: 0 0 0 1px #015c8b;
}

#html .table.-bulario .input:focus
{
    box-shadow: 0 0 0 2px #015c8b;
}

#html .banners-contato .banner-panel h4
{
    font-weight: 600;
}

#html .banners-contato .banner-panel h6
{
    color: #123561 !important;
}

#html .banners-contato .banner-panel p
{
    margin-bottom: 1rem;

    color: inherit !important;
}

#html .banners-contato .banner-panel p:last-child
{
    margin-bottom: 0;
}

#html .banners-contato .banner-panel p + h6
{
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.form-laudos
{
    display: flex;

    align-items: flex-end;
}

.header .navbar
{
    transition: all .3s ease-out;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

    border: 0 none;
    background-color: rgba(51, 51, 51, .5);
}

.listagem-eventos
{
    display: flex;

    flex-wrap: wrap;
}

.listagem-eventos .col-md-4
{
    width: 100%;
}

@media (min-width: 768px)
{
    .listagem-eventos .col-md-4
    {
        max-width: 33.333%;

        flex: 0 0 33.333%;
    }
}

.pagination-eventos
{
    margin-top: 4rem;
}
