@charset 'UTF-8';

html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

// body {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizelegibility;
// }

:active,
:focus {
  outline: 0 !important;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

address,
p,
dl,
blockquote,
pre,
td,
th,
label,
textarea {
  //line-height: 1.61803;
  text-rendering: optimizespeed;
}

// ul,
// menu,
// address,
// dir,
// ol {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
svg {
  max-width: 100%;
  height: auto;
}

object,
embed {
  position: relative;

  max-width: 100%;
  height: 100%;
}

embed,
img,
object,
svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  -webkit-user-drag: none;
}

a,
.btn,
input,
select,
textarea {
  -webkit-transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  -o-transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* 
* BOOTSTRAP 4 reset
*/
.navbar {
  padding-right: 0;
  padding-left: 0;
}

.badge {
  border-radius: 0;
}

@media (max-width: 991px) {
  .lead {
    font-size: 1rem;
  }

  .display-1 {
    font-size: 3rem;
  }

  .display-2 {
    font-size: 2.5rem;
  }

  .display-3 {
    font-size: 2rem;
  }

  .display-4 {
    font-size: 1.5rem;
  }
}

.btn-primary {
  color: white;
}

.list-menu li {
  line-height: 2rem;
}

.fs-normal {
  font-size: 1rem;
}

.fs-small {
  font-size: small;
}

.fs-medium {
  font-size: 1.25rem;
}

.fs-title {
  font-size: 1.75rem;
}

.form-group:last-child,
p:last-child,
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

.pagination {
  font-family: 'Lora';
  font-weight: normal;
  font-style: italic;
}

.pagination .page-item+.page-item {
  margin-left: .5rem;
}

.pagination .page-link {
  border: 0 none !important;
  border-radius: 0 !important;
}

.pagination .page-item:not(.active) .page-link {
  color: #606060;
  -webkit-box-shadow: inset 0 0 0 2px #f3f3f3;
  box-shadow: inset 0 0 0 2px #f3f3f3;
}

.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.33);
}

#html .modal-backdrop {
  display: none !important;
}

#html .btn-success {
  color: white;
}