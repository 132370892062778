@charset 'UTF-8';

#html {
  $colorMain: #0A1E50;
  $colorMainLighter: #123561;
  $colorMainDarker: #071a48;

  //$colorContrast: #d40849;
  $colorContrast: #1e1e54;
  $colorContrastLighter: #dc3d5c;
  $colorContrastDarker: #c90f4a;

  $colorAlt: #20638E;
  $colorAltLighter: #4780a7;
  $colorAltDarker: #175379;

  $colorAlt2: #32373C;
  $colorAlt2Lighter: #4a4c50;
  $colorAlt2Darker: #292e33;

  $colorBlue: #015c8b;
  $colorBlueDarker: #044567;
  $colorCyan: #8cd7f8;
  $colorRed: #f05a72;
  $colorGrey: #cbcacd;

  .c-main {
    color: $colorMain;
  }

  .c-contrast {
    color: $colorContrast;
  }

  .c-alt {
    color: $colorAlt;
  }

  .c-alt2 {
    color: $colorAlt2;
  }

  .c-blue {
    color: $colorBlue;
  }

  .bg-white {
    background-color: white;
  }

  a:hover {
    text-shadow: 0 0 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }

  .link-flag {
    display: inline-block;
    vertical-align: top;
    line-height: 80px;
    margin-right: 2rem;

    .flag {
      width: 24px;
      border-radius: 2px;
    }
  }

  .header {
    .link-login {
      padding: 1rem 0;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .bg--img {
    position: absolute;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    z-index: 0;
    pointer-events: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
  }

  .bg--img.left {
    left: 0;
  }

  .form-check-label {
    margin: 0;

    .form-check-input {
      margin-right: 10px;
    }
  }

  .bg-overlay-dark::before {
    background-color: rgba(1, 60, 106, 0.65);
    // background-image: linear-gradient(to right, #015c8b 0, #8cd7f8 100%);
  }

  .portfolio-item {

    .portfolio--action {
      background-color: rgba(32, 99, 142, 0.85);
    }

    .pos-vertical-center {
      padding: 1rem 2rem;
    }

    p {
      color: white;
      font-size: 14px;
    }
  }

  .footer {
    .logo-footer {
      opacity: 0.3;
      max-width: 200px;
      margin-bottom: 20px;
    }

    .footer-social-icon {
      a {
        width: 2rem;
        height: 2rem;
      }

      .fa {
        font-size: 2rem;
        line-height: 1;
      }
    }
  }


  @keyframes animeDropdown {
    from {
      -webkit-transform: translateY(-3rem);
      transform: translateY(-3rem);
      opacity: 0;
    }
  }

  .dropdown-menu {
    -webkit-animation: animeDropdown 0.5s 0s normal ease both;
    animation: animeDropdown 0.5s 0s normal ease both;
    -webkit-animation-timing-function: cubic-bezier(.160, .560, .170, .999);
    animation-timing-function: cubic-bezier(.160, .560, .170, .999);
  }

  @media only screen and (min-width: 992px) {
    .has-dropdown.-widemenu .dropdown-menu {
      height: 80vh;
      display: none;
      background: transparent !important;

      &::after {
        content: ' ';
        position: absolute;
        right: 0;
        left: 0;
        display: block;
        width: 150vw;
        height: 100%;
        top: 0;
        z-index: -1;
        transform: translateX(-50%);
        // background-color: #fff;
        background-color: rgba(1, 92, 139, 0.96);
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
      }

      li a {
        margin: 0;
        color: #ffffff;
        border-bottom: 1px solid #4780a7;
      }
    }

    .has-dropdown.-widemenu.mega-dropdown {
      position: relative !important;

      &>ul>li {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        transform: translateX(-39%);
        width: 1170px;

        &>ul {
          min-width: 200px;

          &+ul {
            margin-left: 30px;
          }
        }
      }
    }

    .has-dropdown:hover>.dropdown-menu,
    .dropdown-submenu:hover>.dropdown-menu {
      display: block;
    }
  }

  .tabs-timeline {
    .crumbs {
      // background-color: #015c8b;

      ul {
        border: 0;
        box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
        background-color: #20638e;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
        // border-bottom: 2px solid #eee;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
      }

      li {
        flex-grow: 1;
        flex-basis: 0;

        a {
          color: #FFF;
          display: block;
          background: $colorAlt;
          text-decoration: none;
          position: relative;
          height: 50px;
          line-height: 50px;
          padding-right: 33.333%;
          text-align: center;
        }

        &:nth-child(even) {
          a {
            background-color: $colorAltDarker;

            &:before {
              border-color: $colorAltDarker;
              border-left-color: transparent;
            }

            &:after {
              border-left-color: $colorAltDarker;
            }
          }
        }

        &:first-child {
          a {
            padding-left: 15px;
            border-radius: 4px 0 0 0;

            &:before {
              border: none;
            }
          }
        }

        &:last-child {
          a {
            padding-right: 15px;
            border-radius: 0 4px 0 0;

            &:after {
              border: none;
            }
          }
        }

        a {

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 0;
            border: 0 solid $colorAlt;
            border-width: 25px 10px;
            width: 0;
            height: 0;
            transition: all .24s cubic-bezier(0, 0, .58, 1);
            transform: translate3d(0, 0, 0);
          }

          &:before {
            left: -20px;
            border-left-color: transparent;
          }

          &:after {
            left: 100%;
            border-color: transparent;
            border-left-color: $colorAlt;
          }

          &:hover {
            color: #017097;
            font-weight: 600;
            background-color: #ffffff;

            &:before {
              border-color: #ffffff;
              border-left-color: transparent;
            }

            &:after {
              border-left-color: #ffffff;
            }
          }

          &:active {
            color: #017097;
            font-weight: 600;
            background-color: #ffffff;

            &:before {
              border-color: #ffffff;
              border-left-color: transparent;
            }

            &:after {
              border-left-color: #ffffff;
            }
          }
        }

        &.active {
          a {
            color: #017097;
            font-weight: 600;
            background-color: #ffffff;

            &:before {
              border-color: #ffffff;
              border-left-color: transparent;
            }

            &:after {
              border-left-color: #ffffff;
            }
          }
        }
      }
    }

    .tab-content>.tab-pane {
      padding: 0;

      .blog-entry {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .entry--bio {

        &+.entry--bio {
          margin-top: 30px;
        }

        img {
          max-width: 156px;
          float: left;
          margin-right: 30px
        }
      }
    }
  }

  .rev_slider .slide--headline-border {
    position: absolute;
    height: 550%;
    width: 106%;
    top: 0;
    left: 0;
    transform: translateY(-15%) translateX(-3%);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.25);
    z-index: -1;
    pointer-events: none;
  }

  .table.-produto {
    width: 100%;
    table-layout: fixed;
    padding: 0;
    margin: 0;

    thead {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
    }

    td:first-child {
      width: 40%;
    }
  }

  .pagination.-produto {
    padding-top: 3rem;
    width: 100%;
    text-align: center;

    &>li {

      &:hover {

        a {
          color: white;
        }
      }
    }
  }

  .tab-content.-bulario {

    .tab-pane {
      padding-top: 6rem;
    }
  }

  .table.-bulario {

    th,
    td {
      line-height: 1 !important;
      padding: 1rem 2rem;
      vertical-align: middle;
    }

    .item {

      .fa {
        margin-right: 1rem;
        font-size: 1.5rem;
      }
    }

    .th-input-search {
      width: 25%;
    }

    .input {
      border-radius: 3rem;
      box-shadow: 0 0 0 1px #aaa;
      border: 0;
      padding: 0 2rem;
      height: auto;
      line-height: 3rem;
      font-weight: normal;
      width: 100%;
    }

    .input:hover {
      box-shadow: 0 0 0 1px #015c8b;
    }

    .input:focus {
      box-shadow: 0 0 0 2px #015c8b;
    }
  }

  .banners-contato .banner-panel h4 {
    font-weight: 600;
  }

  .banners-contato .banner-panel h6 {
    color: #123561 !important;
  }

  .banners-contato .banner-panel p {
    color: inherit !important;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &+h6 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.form-laudos {
  display: flex;
  align-items: flex-end;
}

.header .navbar {
  border: 0 none;
  background-color: rgba(51, 51, 51, 0.5);
  transition: all .3s ease-out;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.listagem-eventos {
  display: flex;
  flex-wrap: wrap;
  
  .col-md-4 {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .col-md-4 {
      max-width: 33.333%;
      flex: 0 0 33.333%;
    }
  }
}

.pagination-eventos {
  margin-top: 4rem;
}