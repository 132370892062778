@charset 'UTF-8';

/**
 * Micro clearfix
 * Copyright © Nicolas Gallagher
 * Source: [http://nicolasgallagher.com/micro-clearfix-hack/]
 */
.cf::before,
.cf::after,
.clearfix::before,
.clearfix::after {
  display: table;
  content: " ";
}

.cf::after,
.clearfix::after {
  clear: both;
}

.main-content,
.main-header,
.main-footer {
  clear: both;
}

.embed-responsive.-anime img {
  transform: scale(1);
  transition: all .4s cubic-bezier(0, 0, .58, 1);
  transform: translate3d(0, 0, 0);
}

.embed-responsive.-anime:hover img {
  transform: scale(1.2);
}

.bg-image {
  overflow: hidden;

  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
}

.bg-facebook {
  background-color: #4267b2
}

.bg-instagram {
  background-image: radial-gradient(circle farthest-corner at 32% 106%, #ffe17d 0, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), linear-gradient(135deg, #234bd7 12%, #c33cbe 58%)
}

.bg-twitter {
  background-color: #03b8ec
}

.bg-linkedin {
  background-color: #0284ba
}

.bg-googleplus {
  background-color: #ee5352
}

.bg-youtube,
.bg-youTube {
  background-color: #ff0416
}

.bg-pinterest {
  background-color: #bd081c;
}

.line-clamp,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;

  -o-text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

/* Techniques that make content inaccessible (invisible to all users).
* [https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html]*/
.invisible,
.visually-hidden {
  position: absolute !important;

  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);

  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;

  border: 0 !important;
}

:root:hover .invisible *,
:root:hover .visually-hidden * {
  display: none !important;
}

.truncate,
.ellipsis {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;

  -o-text-overflow: ellipsis;
}

.vertical-align {
  position: relative;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* select */
.styled-select {
  position: relative;

  display: inline-block;

  width: 100%;

  white-space: nowrap;
}

.styled-select select option[selected] {
  cursor: default;

  color: black;
  background-color: #f3f3a0;
  text-shadow: 0 0 0;
}

.styled-select select {
  display: block;
  overflow: hidden;

  width: 100%;
  padding-right: 37px;

  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;

  -o-text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.styled-select::after {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0;

  width: 10px;
  height: 10px;
  margin: -2px 1em 0 0;

  content: '';
  -webkit-transition: all 180ms ease-out;
  -o-transition: all 180ms ease-out;
  transition: all 180ms ease-out;
  -webkit-transform: translateY(-50%) scale(.8) rotate(45deg);
  -ms-transform: translateY(-50%) scale(.8) rotate(45deg);
  transform: translateY(-50%) scale(.8) rotate(45deg);

  border: solid #b0b0b0;
  border-width: 0 3px 3px 0;
}

.styled-select:hover::after,
.styled-select:focus::after {
  -webkit-transform: translateY(-50%) scale(.9) rotate(45deg);
  -ms-transform: translateY(-50%) scale(.9) rotate(45deg);
  transform: translateY(-50%) scale(.9) rotate(45deg);

  border-color: #999;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;

    -webkit-filter: alpha (opacity=0);
    -moz-filter: alpha (opacity=0);
    filter: alpha (opacity=0);
  }
}

.fade-in {
  -webkit-animation: fadeIn 1s normal none;
  animation: fadeIn 1s normal none;
  -webkit-animation-timing-function: cubic-bezier(.770, .000, .175, 1.000);
  animation-timing-function: cubic-bezier(.770, .000, .175, 1.000);
}

.fade-in.-xs {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fade-in.-s {
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
}

.fade-in.-m {
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
}

.fade-in.-l {
  -webkit-animation-duration: 2500ms;
  animation-duration: 2500ms;
}

.fade-in.-xl {
  -webkit-animation-duration: 3000ms;
  animation-duration: 3000ms;
}

.fade-in.-xxl {
  -webkit-animation-duration: 3500ms;
  animation-duration: 3500ms;
}

.cover {
  position: relative;
  z-index: 1;

  display: block;
  overflow: hidden;

  width: 100%;

  text-align: center;
}

.media .cover {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 4rem;
  flex: 1 0 4rem;

  max-width: 4rem;
}

.list-media .media .cover {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;

  max-width: 25%;
}

.cover::before {
  display: block;

  padding-top: 100%;

  content: '';
}

.cover img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  width: auto;
  max-width: none;
  height: 100%;

  border: 0;
}

.cover::after {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  content: '';

  opacity: 0;
  background-image: -webkit-gradient(linear, right top, left top, from(#434c63), to(#bc9b72));
  background-image: -webkit-linear-gradient(right, #434c63, #bc9b72);
  background-image: -o-linear-gradient(right, #434c63, #bc9b72);
  background-image: linear-gradient(to left, #434c63, #bc9b72);
}

.cover img,
.cover::after {
  -webkit-transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  -o-transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

a.cover::after {
  font-family: 'fonticon';
  font-size: 2rem;
  line-height: 1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  content: '\e92d';
  -webkit-transform: scale(.95);
  -ms-transform: scale(.95);
  transform: scale(.95);

  color: white;
}

.cover:hover::after {
  font-size: 2.25rem;

  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

  opacity: .85;
}

.cover:hover img {
  -webkit-transition: all 2s cubic-bezier(.0, .0, .58, 1.0);
  -o-transition: all 2s cubic-bezier(.0, .0, .58, 1.0);
  transition: all 2s cubic-bezier(.0, .0, .58, 1.0);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.embed-content {
  position: absolute;
  z-index: auto;
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.embed-content.-caption {
  z-index: 20;
}

.embed-content.-top {
  top: 0;
  bottom: auto;

  height: auto;
  padding: .5rem;
}

.embed-content.-bottom {
  top: auto;
  bottom: 0;

  height: auto;
  padding: .5rem;
}

.embed-content.-middle {
  top: 50%;
  bottom: auto;

  height: auto;
  padding: .5rem;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* slick slider */
.carousel,
.slick-slider {
  position: relative;
  z-index: auto;

  clear: both;
}

#html .slide-control {
  font-size: 2.25rem;
  line-height: 3rem;

  position: absolute;
  z-index: 2;
  top: 50%;

  width: 3rem;
  height: 3rem;
  padding: 0;

  -webkit-transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  -o-transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  opacity: 1;
  color: #333;
  border: 0 none;
  border-radius: 0;
}

#html .slide-control .fa {
  position: relative;

  -webkit-transition: all .14s cubic-bezier(.0, .0, .58, 1.0);
  -o-transition: all .14s cubic-bezier(.0, .0, .58, 1.0);
  transition: all .14s cubic-bezier(.0, .0, .58, 1.0);
}

#html .slide-control .fa::after {
  position: absolute;
  z-index: 3;
  top: 50%;

  display: inline-block;

  width: 3rem;
  height: 3px;

  content: '';

  background-color: #333;
}

#html .slide-control.-prev {
  left: 0;

  margin-left: -1.5rem;
}

#html .slide-control.-prev .fa {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#html .slide-control.-prev .fa::after {
  left: 2px;
}

#html .slide-control.-next {
  right: 0;

  margin-right: -1.5rem;
}

#html .slide-control.-next .fa {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

#html .slide-control.-next .fa::after {
  right: 2px;
}

#html .slide-control:hover .fa {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.slick-dots {
  position: relative;

  padding-top: 2rem;
}

.slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
}

.slick-dots li+li {
  margin-left: 1rem;
}

.slick-dots li button {
  width: 12px;
  height: 12px;
  margin: 0;

  border-width: 1px;
  border-radius: 0;
  background-color: #e7e7e7;
}

.slick-dots li button::before {
  display: none;

  content: '';
}

.slick-dots button:hover,
.slick-dots .slick-active button {
  background-color: #c6a381;
}

#html .slick-dots button:hover::after,
#html .slick-dots .slick-active button::after {
  position: relative;
  z-index: 1;
  top: -15px;
  bottom: 100%;

  display: block;

  width: 10px;
  height: 10px;
  margin-left: -4px;

  content: '';

  opacity: 1;
  border: 5px solid transparent;
  border-bottom: 5px solid #c6a381;
  background: none !important;
}

.slick-hidden.-arrows .slick-dots,
.slick-hidden.-controls .slide-control {
  display: none !important;
  visibility: hidden !important;
}

.slick-dotted.slick-slider.slick-hidden.-controls {
  margin-bottom: 0;
}

.form.-grey .form-control {
  border: 0 none;
  border-radius: 0;
  background-color: #f3f3f3;
}

#html .form-control.form-control-lg {
  font-size: 1rem;

  padding-right: 1rem;
  padding-left: 1rem;
}

.form.-white .form-control {
  border: 0 none;
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: inset 0 -2px 0 0 #e7e7e7;
  box-shadow: inset 0 -2px 0 0 #e7e7e7;
}

.form.-white .form-control:hover {
  -webkit-box-shadow: inset 0 -2px 0 0 #c6a381;
  box-shadow: inset 0 -2px 0 0 #c6a381;
}

.form.-white .form-control:focus {
  background-color: #f7f7f7;
  -webkit-box-shadow: inset 0 -2px 0 0 #c6a381;
  box-shadow: inset 0 -2px 0 0 #c6a381;
}

.form.-hidden-labels label {
  position: absolute;

  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;
}

form div.error {
  font-size: .8rem;
  line-height: 2;

  display: block;
  clear: both;

  -webkit-animation: fadeIn 1s normal none;
  animation: fadeIn 1s normal none;
  -webkit-animation-timing-function: cubic-bezier(.770, .000, .175, 1.000);
  animation-timing-function: cubic-bezier(.770, .000, .175, 1.000);
  white-space: normal;

  color: #e23535;
}

form .text-danger,
form .text-success {
  font-size: .85rem;
  line-height: 2;
}

input.error,
select.error,
textarea.error {
  color: #dc7878;
  background-color: #ffe4e4;
  -webkit-box-shadow: inset 0 0 0 1px #dc7878;
  box-shadow: inset 0 0 0 1px #dc7878;
}

input.success,
select.success,
textarea.success {
  color: #49b381;
  background-color: #ebfff9;
  -webkit-box-shadow: inset 0 0 0 1px #49b381;
  box-shadow: inset 0 0 0 1px #49b381;
}

/* 
* BOOTSTRAP 4 reset
*/
.navbar {
  padding-right: 0;
  padding-left: 0;
}

.badge {
  border-radius: 0;
}

@media (max-width: 991px) {
  .lead {
    font-size: 1rem;
  }

  .display-1 {
    font-size: 3rem;
  }

  .display-2 {
    font-size: 2.5rem;
  }

  .display-3 {
    font-size: 2rem;
  }

  .display-4 {
    font-size: 1.5rem;
  }
}

.btn-primary {
  color: white;
}

.list-menu li {
  line-height: 2rem;
}

.fs-normal {
  font-size: 1rem;
}

.fs-small {
  font-size: small;
}

.fs-medium {
  font-size: 1.25rem;
}

.fs-title {
  font-size: 1.75rem;
}

.form-group:last-child,
p:last-child,
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

.pagination {
  font-family: 'Lora';
  font-weight: normal;
  font-style: italic;
}

.pagination .page-item+.page-item {
  margin-left: .5rem;
}

.pagination .page-link {
  border: 0 none !important;
  border-radius: 0 !important;
}

.pagination .page-item:not(.active) .page-link {
  color: #606060;
  -webkit-box-shadow: inset 0 0 0 2px #f3f3f3;
  box-shadow: inset 0 0 0 2px #f3f3f3;
}

.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.33);
}

#html .modal-backdrop {
  display: none !important;
}

#html .btn-success {
  color: white;
}